@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val ic_add: DrawableResource by 
      lazy { init_ic_add() }

  public val ic_close: DrawableResource by 
      lazy { init_ic_close() }

  public val ic_delete: DrawableResource by 
      lazy { init_ic_delete() }

  public val ic_done: DrawableResource by 
      lazy { init_ic_done() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("ic_add", CommonMainDrawable0.ic_add)
  map.put("ic_close", CommonMainDrawable0.ic_close)
  map.put("ic_delete", CommonMainDrawable0.ic_delete)
  map.put("ic_done", CommonMainDrawable0.ic_done)
}

public val Res.drawable.ic_add: DrawableResource
  get() = CommonMainDrawable0.ic_add

private fun init_ic_add(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/resources/drawable/ic_add.xml", -1, -1),
    )
)

public val Res.drawable.ic_close: DrawableResource
  get() = CommonMainDrawable0.ic_close

private fun init_ic_close(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_close",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/resources/drawable/ic_close.xml", -1, -1),
    )
)

public val Res.drawable.ic_delete: DrawableResource
  get() = CommonMainDrawable0.ic_delete

private fun init_ic_delete(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_delete",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/resources/drawable/ic_delete.xml", -1, -1),
    )
)

public val Res.drawable.ic_done: DrawableResource
  get() = CommonMainDrawable0.ic_done

private fun init_ic_done(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_done",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/resources/drawable/ic_done.xml", -1, -1),
    )
)
